import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

export default function Terms() {
    const location = useLocation();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(location.pathname);

    const handleTabClick = (tabId) => {
        navigate(tabId);
        setActiveTab(tabId);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);


    return (
        <>
            <Header isAddbg={true} />
            <div className='terms-container mb-5'>
                <h2 className='mt-2'>Legal</h2>
                <Tabs
                    defaultActiveKey={activeTab}
                    id="justify-tab-example"
                    className="mt-3"
                    justify
                    onSelect={handleTabClick}
                    variant="tabs"
                    fill

                >
                    <Tab eventKey="/legal/us/terms" title="Terms of Service">
                        <div className="mt-4 termsofservice">
                            <div>
                                <h2 className='termsofservice-heading'>Terms and Conditions</h2>
                                <p className='mt-4 termsofservice-content'>
                                    Last Updated: Sep 30, 2014
                                </p>
                                <p className='termsofservice-content'>
                                    The terms and conditions stated herein (collectively, the "Agreement") constitute a legal agreement between you and Keepe Up, Inc., a Washington corporation (the "Company"). In order to use the Service (defined below) and the associated Application (defined below) you must agree to the terms and conditions that are set out below. By using or receiving any services supplied to you by the Company (collectively, the "Service"), and downloading, installing or using any associated application supplied by the Company which purpose is to enable you to use the Service (collectively, the "Application"), you hereby expressly acknowledge and agree to be bound by the terms and conditions of the Agreement, and any future amendments and additions to this Agreement as published from time to time at <a href="https://www.keepe.com/legal/us/terms" rel="noreferrer" target="_blank">https://www.keepe.com/legal/us/terms</a> or through the Service.
                                </p>
                                <p className='termsofservice-content'>
                                    The Company reserves the right to modify the terms and conditions of this Agreement or its policies relating to the Service or Application at any time, effective upon posting of an updated version of this Agreement on the Service or Application. You are responsible for regularly reviewing this Agreement. Continued use of the Service or Application after any such changes shall constitute your consent to such changes.
                                </p>
                                <p className='termsofservice-content'>
                                    THE COMPANY DOES NOT PROVIDE CONSTRUCTION SERVICES, AND THE COMPANY IS NOT A CONSTRUCTION SERVICE PROVIDER. IT IS UP TO THE THIRD PARTY CONSTRUCTION PROVIDER OR OPERATOR TO OFFER CONSTRUCTION SERVICES WHICH MAY BE SCHEDULED THROUGH USE OF THE APPLICATION OR SERVICE. THE COMPANY OFFERS INFORMATION AND A METHOD TO OBTAIN SUCH THIRD PARTY CONSTRUCTION SERVICES, BUT DOES NOT AND DOES NOT INTEND TO PROVIDE CONSTRUCTION SERVICES OR ACT IN ANY WAY AS A CONSTRUCTION SERVICE PROVIDER, AND HAS NO RESPONSIBILITY OR LIABILITY FOR ANY CONSTRUCTION SERVICES PROVIDED TO YOU BY SUCH THIRD PARTIES.
                                </p>
                                <h2 className='termsofservice-heading mt-4'>Key Content-related Terms </h2>
                                <ul className="list">
                                    <li>
                                        "Content" means text, graphics, images, music, software (excluding the Application), audio, video, information or other materials.
                                    </li>
                                    <li>
                                        "Company Content" means Content that Company makes available through the Service or Application, including any Content licensed from a third party, but excluding User Content.
                                        “User” means a person who accesses or uses the Service or Application.
                                    </li>
                                    <li>
                                        "User Content" means Content that a User posts, uploads, publishes, submits or transmits to be made available through the Service or Application.
                                    </li>
                                    <li>
                                        "Collective Content" means, collectively, Company Content and User Content.
                                    </li>
                                </ul>
                                <h2 className='termsofservice-heading mt-4'>Representations and Warranties</h2>
                                <p className='termsofservice-content'>
                                    By using the Application or Service, you expressly represent and warrant that you are legally entitled to enter this Agreement. If you reside in a jurisdiction that restricts the use of the Service because of age, or restricts the ability to enter into agreements such as this one due to age, you must abide by such age limits and you must not use the Application and Service. Without limiting the foregoing, the Service and Application is not available to children (persons under the age of 18). By using the Application or Service, you represent and warrant that you are at least 18 years old. By using the Application or the Service, you represent and warrant that you have the right, authority and capacity to enter into this Agreement and to abide by the terms and conditions of this Agreement. Your participation in using the Service and/or Application is for your sole, personal use. You may not authorize others to use your user status, and you may not assign or otherwise transfer your user account to any other person or entity. When using the Application or Service you agree to comply with all applicable laws from your home nation, the country, state and city in which you are present while using the Application or Service.
                                </p>
                                <p className='termsofservice-content'>
                                    You may only access the Service using authorized means. It is your responsibility to check to ensure you download the correct Application for your device. The Company is not liable if you do not have a compatible handset or if you have downloaded the wrong version of the Application for your handset. The Company reserves the right to terminate this Agreement should you be using the Service or Application with an incompatible or unauthorized device.
                                </p>
                                <p className='termsofservice-content'>
                                    By using the Application or the Service, you agree that:
                                </p>
                                <ul className="list">
                                    <li>
                                        You will only use the Service or Application for lawful purposes; you will not use the Services for sending or storing any unlawful material or for fraudulent purposes.
                                    </li>
                                    <li>
                                        You will not use the Service or Application to cause nuisance, annoyance or inconvenience.
                                    </li>
                                    <li>
                                        You will not impair the proper operation of the network.
                                    </li>
                                    <li>
                                        You will not try to harm the Service or Application in any way whatsoever.
                                    </li>
                                    <li>
                                        You will not copy, or distribute the Application or other content without written permission from the Company.
                                    </li>
                                    <li>
                                        You will only use the Application and Service for your own use and will not resell it to a third party.
                                    </li>
                                    <li>
                                        You will keep secure and confidential your account password or any identification we provide you which allows access to the Service.
                                    </li>
                                    <li>
                                        You will provide us with whatever proof of identity we may reasonably request.
                                    </li>
                                    <li>
                                        You will only use an access point or 3/4G data account (AP) which you are authorized to use.
                                    </li>
                                    <li>
                                        You are aware that when requesting construction services by SMS, standard messaging charges will apply.
                                    </li>
                                </ul>
                                <h2 className='termsofservice-heading mt-4'>License grant, Restrictions and Copyright policy</h2>
                                <h4 className='termsofservice-subheading mt-4'>Licenses granted by company to company content and user content</h4>
                                <p className='termsofservice-content'>
                                    Subject to your compliance with the terms and conditions of this Agreement, Company grants you a limited, non-exclusive, non-transferable license: (i) to view, download and print any Company Content solely for your personal and non-commercial purposes; and (ii) to view any User Content to which you are permitted access solely for your personal and non-commercial purposes. You have no right to sublicense the license rights granted in this section.
                                    You will not use, copy, adapt, modify, prepare derivative works based upon, distribute, license, sell, transfer, publicly display, publicly perform, transmit, stream, broadcast or otherwise exploit the Service, Application or Collective Content, except as expressly permitted in this Agreement. No licenses or rights are granted to you by implication or otherwise under any intellectual property rights owned or controlled by Company or its licensors, except for the licenses and rights expressly granted in this Agreement.
                                </p>
                                <h4 className='termsofservice-subheading mt-4'>License granted by user</h4>
                                <p className='termsofservice-content'>
                                    We may, in our sole discretion, permit Users to post, upload, publish, submit or transmit User Content. By making available any User Content on or through the Service or Application, you hereby grant to Company a worldwide, irrevocable, perpetual, non-exclusive, transferable, royalty-free license, with the right to sublicense, to use, view, copy, adapt, modify, distribute, license, sell, transfer, publicly display, publicly perform, transmit, stream, broadcast and otherwise exploit such User Content only on, through or by means of the Service or Application. Company does not claim any ownership rights in any User Content and nothing in this Agreement will be deemed to restrict any rights that you may have to use and exploit any User Content.
                                </p>
                                <p className='termsofservice-content'>
                                    You acknowledge and agree that you are solely responsible for all User Content that you make available through the Service or Application. Accordingly, you represent and warrant that: (i) you either are the sole and exclusive owner of all User Content that you make available through the Service or Application or you have all rights, licenses, consents and releases that are necessary to grant to Company and to the rights in such User Content, as contemplated under this Agreement; and (ii) neither the User Content nor your posting, uploading, publication, submission or transmittal of the User Content or Company’s use of the User Content (or any portion thereof) on, through or by means of the Service or Application will infringe, misappropriate or violate a third party’s patent, copyright, trademark, trade secret, moral rights or other intellectual property rights, or rights of publicity or privacy, or result in the violation of any applicable law or regulation.
                                </p>
                                <h4 className='termsofservice-subheading mt-4'>Application license</h4>
                                <p className='termsofservice-content'>
                                    Subject to your compliance with this Agreement, Company grants you a limited non-exclusive, non-transferable license to download and install a copy of the Application on a single mobile device or computer that you own or control and to run such copy of the Application solely for your own personal use. Furthermore, with respect to any Application accessed through or downloaded from the Apple App Store (“App Store Sourced Application”), you will use the App Store Sourced Application only: (i) on an Apple-branded product that runs iOS (Apple’s proprietary operating system software); and (ii) as permitted by the “Usage Rules” set forth in the Apple App Store Terms of Service. Company reserves all rights in and to the Application not expressly granted to you under this Agreement.
                                </p>
                                <h2 className='termsofservice-heading mt-4'>Accessing and downloading the application from iTunes</h2>
                                <ul className="list">
                                    <li>
                                        You acknowledge and agree that (i) this Agreement is concluded between you and Company only, and not Apple, and (ii) Company, not Apple, is solely responsible for the App Store Sourced Application and content thereof. Your use of the App Store Sourced Application must comply with the App Store Terms of Service.
                                    </li>
                                    <li>
                                        You acknowledge that Apple has no obligation whatsoever to furnish any maintenance and support services with respect to the App Store Sourced Application.
                                    </li>
                                    <li>
                                        In the event of any failure of the App Store Sourced Application to conform to any applicable warranty, you may notify Apple, and Apple will refund the purchase price for the App Store Sourced Application to you and to the maximum extent permitted by applicable law, Apple will have no other warranty obligation whatsoever with respect to the App Store Sourced Application. As between Company and Apple, any other claims, losses, liabilities, damages, costs or expenses attributable to any failure to conform to any warranty will be the sole responsibility of Company.
                                    </li>
                                    <li>
                                        You and Company acknowledge that, as between Company and Apple, Apple is not responsible for addressing any claims you have or any claims of any third party relating to the App Store Sourced Application or your possession and use of the App Store Sourced Application, including, but not limited to: (i) product liability claims; (ii) any claim that the App Store Sourced Application fails to conform to any applicable legal or regulatory requirement; and (iii) claims arising under consumer protection or similar legislation.
                                    </li>
                                    <li>
                                        You and Company acknowledge that, in the event of any third party claim that the App Store Sourced Application or your possession and use of that App Store Sourced Application infringes that third party’s intellectual property rights, as between Company and Apple, Company, not Apple, will be solely responsible for the investigation, defense, settlement and discharge of any such intellectual property infringement claim to the extent required by this Agreement.
                                    </li>
                                    <li>
                                        You and Company acknowledge and agree that Apple, and Apple’s subsidiaries, are third party beneficiaries of this Agreement as related to your license of the App Store Sourced Application, and that, upon your acceptance of the terms and conditions of this Agreement, Apple will have the right (and will be deemed to have accepted the right) to enforce this Agreement as related to your license of the App Store Sourced Application against you as a third party beneficiary thereof.
                                    </li>
                                    <li>
                                        Without limiting any other terms of this Agreement, you must comply with all applicable third party terms of agreement when using the App Store Sourced Application.
                                    </li>
                                </ul>
                                <p className='termsofservice-content'>
                                    You shall not (i) license, sublicense, sell, resell, transfer, assign, distribute or otherwise commercially exploit or make available to any third party the Service or the Application in any way; (ii) modify or make derivative works based upon the Service or the Application; (iii) create Internet "links" to the Service or "frame" or "mirror" any Application on any other server or wireless or Internet-based device; (iv) reverse engineer or access the Application in order to (a) build a competitive product or service, (b) build a product using similar ideas, features, functions or graphics of the Service or Application, or (c) copy any ideas, features, functions or graphics of the Service or Application, or (v) launch an automated program or script, including, but not limited to, web spiders, web crawlers, web robots, web ants, web indexers, bots, viruses or worms, or any program which may make multiple server requests per second, or unduly burdens or hinders the operation and/or performance of the Service or Application.
                                    You shall not: (i) send spam or otherwise duplicative or unsolicited messages in violation of applicable laws; (ii) send or store infringing, obscene, threatening, libelous, or otherwise unlawful or tortious material, including material harmful to children or violative of third party privacy rights; (iii) send or store material containing software viruses, worms, Trojan horses or other harmful computer code, files, scripts, agents or programs; (iv) interfere with or disrupt the integrity or performance of the Application or Service or the data contained therein; or (v) attempt to gain unauthorized access to the Application or Service or its related systems or networks.
                                </p>
                                <p className='termsofservice-content'>
                                    Company will have the right to investigate and prosecute violations of any of the above to the fullest extent of the law. Company may involve and cooperate with law enforcement authorities in prosecuting users who violate this Agreement. You acknowledge that Company has no obligation to monitor your access to or use of the Service, Application or Collective Content or to review or edit any Collective Content, but has the right to do so for the purpose of operating the Service and Application, to ensure your compliance with this Agreement, or to comply with applicable law or the order or requirement of a court, administrative agency or other governmental body. Company reserves the right, at any time and without prior notice, to remove or disable access to any Collective Content that Company, at its sole discretion, considers to be in violation of this Agreement or otherwise harmful to the Service or Application.
                                </p>
                                <h4 className='termsofservice-subheading mt-4'>Copyright policy</h4>
                                <p className='termsofservice-content'>
                                    Company respects copyright law and expects its users to do the same. It is Company’s policy to terminate in appropriate circumstances Users or other account holders who repeatedly infringe or are believed to be repeatedly infringing the rights of copyright holders. Please see Company’s Copyright Policy at <a href="https://www.keepe.com/legal/us/copyright" rel="noreferrer" target="_blank">https://www.keepe.com/legal/us/copyright</a> for further information.
                                </p>
                                <h2 className='termsofservice-heading mt-4'>Payment terms</h2>
                                <p className='termsofservice-content'>
                                    Any fees that the Company may charge you for the Application or Service, are due immediately and are non-refundable. This no refund policy shall apply at all times regardless of your decision to terminate your usage, our decision to terminate your usage, disruption caused to our Application or Service either planned, accidental or intentional, or any reason whatsoever. The Company reserves the right to determine final prevailing pricing - Please note the pricing information published on the website may not reflect the prevailing pricing.
                                </p>
                                <p className='termsofservice-content'>
                                    The Company, at its sole discretion, make promotional offers with different features and different rates to any of our customers. These promotional offers, unless made to you, shall have no bearing whatsoever on your offer or contract. The Company may change the fees for our Service or Application, as we deem necessary for our business. We encourage you to check back at our website periodically if you are interested about how we charge for the Service of Application.
                                </p>
                                <h2 className='termsofservice-heading mt-4'>SMS messaging</h2>
                                <p className='termsofservice-content'>
                                    If you select this feature, and have SMS service from one of the supported Carriers (T-Mobile, Verizon Wireless, AT&amp;T, Sprint, Nextel, Boost, U.S. Cellular, MetroPCS and Cricket), you can request service via SMS and get notified if you request pickups through our Applications. Message and data rates may apply.
                                </p>
                                <p className='termsofservice-content'>
                                    You will only receive messages from Company if you make a service request. If you change your mobile phone service provider the service may be deactivated and you will need to re-enroll in the notification service. Company reserves the right to cancel the notification service at any time; you may cancel (opt-out) the service by texting the word STOP to 1-425-276-7338 from your mobile phone. For more information, please text the word HELP to 1-425-276-7338, or call 1-866-947-6215.
                                </p>
                                <h2 className='termsofservice-heading mt-4'>Intellectual Property ownership</h2>
                                <p className='termsofservice-content'>
                                    The Company alone (and its licensors, where applicable) shall own all right, title and interest, including all related intellectual property rights, in and to the Application and the Service and any suggestions, ideas, enhancement requests, feedback, recommendations or other information provided by you or any other party relating to the Application or the Service. This Agreement is not a sale and does not convey to you any rights of ownership in or related to the Application or the Service, or any intellectual property rights owned by the Company. The Company name, the Company logo, and the product names associated with the Application and Service are trademarks of the Company or third parties, and no right or license is granted to use them.
                                </p>
                                <h2 className='termsofservice-heading mt-4'>Third party interactions</h2>
                                <p className='termsofservice-content'>
                                    During use of the Application and Service, you may enter into correspondence with, purchase goods and/or services from, or participate in promotions of third party service providers, advertisers or sponsors showing their goods and/or services through the Application or Service. Any such activity, and any terms, conditions, warranties or representations associated with such activity, is solely between you and the applicable third-party. The Company and its licensors shall have no liability, obligation or responsibility for any such correspondence, purchase, transaction or promotion between you and any such third-party. The Company does not endorse any sites on the Internet that are linked through the Service or Application, and in no event shall the Company or its licensors be responsible for any content, products, services or other materials on or available from such sites or third party providers. The Company provides the Application and Service to you pursuant to the terms and conditions of this Agreement. You recognize, however, that certain third-party providers of goods and/or services may require your agreement to additional or different terms and conditions prior to your use of or access to such goods or services, and the Company disclaims any and all responsibility or liability arising from such agreements between you and the third party providers.
                                    The Company may rely on third party advertising and marketing supplied through the Application or Service and other mechanisms to subsidize the Application or Service. By agreeing to these terms and conditions you agree to receive such advertising and marketing. If you do not want to receive such advertising you should notify us in writing. The Company reserves the right to charge you a higher fee for the Service or Application should you choose not to receive these advertising services. This higher fee, if applicable, will be posted on the Company's website located at http://www.keepe.com. The Company may compile and release information regarding you and your use of the Application or Service on an anonymous basis as part of a customer profile or similar report or analysis. You agree that it is your responsibility to take reasonable precautions in all actions and interactions with any third party you interact with through the Service.
                                </p>
                                <h2 className='termsofservice-heading mt-4'>Indemnification</h2>
                                <p className='termsofservice-content'>
                                    By entering into this Agreement and using the Application or Service, you agree that you shall defend, indemnify and hold the Company, its licensors and each such party's parent organizations, subsidiaries, affiliates, officers, directors, Users, employees, attorneys and agents harmless from and against any and all claims, costs, damages, losses, liabilities and expenses (including attorneys' fees and costs) arising out of or in connection with: (a) your violation or breach of any term of this Agreement or any applicable law or regulation, whether or not referenced herein; (b) your violation of any rights of any third party, including providers of construction services arranged via the Service or Application, or (c) your use or misuse of the Application or Service.
                                </p>
                                <h2 className='termsofservice-heading mt-4'>Disclaimer of warranties</h2>
                                <p className='termsofservice-content'>
                                    the company makes no representation, warranty, or guaranty as to the reliability, timeliness, quality, suitability, availability, accuracy or completeness of the service or application. the company does not represent or warrant that (a) the use of the service or application will be secure, timely, uninterrupted or error-free or operate in combination with any other hardware, application, system or data, (b) the service or application will meet your requirements or expectations, (c) any stored data will be accurate or reliable, (d) the quality of any products, services, information, or other material purchased or obtained by you through the service will meet your requirements or expectations, (e) errors or defects in the service or application will be corrected, or (f) the service or the server(s) that make the service available are free of viruses or other harmful components. the service and application is provided to you strictly on an "as is" basis. all conditions, representations and warranties, whether express, implied, statutory or otherwise, including, without limitation, any implied warranty of merchantability, fitness for a particular purpose, or non-infringement of third party rights, are hereby disclaimed to the maximum extent permitted by applicable law by the company. the company makes no representation, warranty, or guaranty as to the reliability, safety, timeliness, quality, suitability or availability of any services, products or goods obtained by third parties through the use of the service or application. you acknowledge and agree that the entire risk arising out of your use of the application and service, and any third party services or products remains solely with you, to the maximum extent permitted by law.
                                </p>
                                <h2 className='termsofservice-heading mt-4'>Internet delays</h2>
                                <p className='termsofservice-content'>
                                    the company's service and application may be subject to limitations, delays, and other problems inherent in the use of the internet and electronic communications. the company is not responsible for any delays, delivery failures, or other damage resulting from such problems.
                                </p>
                                <h2 className='termsofservice-heading mt-4'>Limitation of liability</h2>
                                <p className='termsofservice-content'>
                                    in no event shall the company and/or its licensors be liable to anyone for any indirect, punitive, special, exemplary, incidental, consequential or other damages of any type or kind (including personal injury, loss of data, revenue, profits, use or other economic advantage). the company and/or its licensors shall not be liable for any loss, damage or injury which may be incurred by you, including by not limited to loss, damage or injury arising out of, or in any way connected with the service or application, including but not limited to the use or inability to use the service or application, any reliance placed by you on the completeness, accuracy or existence of any advertising, or as a result of any relationship or transaction between you and any third party service provider, advertiser or sponsor whose advertising appears on the website or is referred by the service or application, even if the company and/or its licensors have been previously advised of the possibility of such damages.
                                </p>
                                <p className='termsofservice-content'>
                                    the company may introduce you to third party construction service providers for the purposes of providing construction services. we will not assess the suitability, legality or ability of any third party construction service providers and you expressly waive and release the company from any and all any liability, claims or damages arising from or in any way related to the third party construction service provider. you acknowledge that third party construction service providers providing construction service services requested through keepe may offer construction services and may not be professionally licensed or permitted. the company will not be a party to disputes, negotiations of disputes between you and any third party providers. we cannot and will not play any role in managing payments between you and the third party providers. responsibility for the decisions you make regarding services offered via the application or service (with all its implications) rests solely with you. we will not assess the suitability, legality or ability of any such third parties and you expressly waive and release the company from any and all liability, claims, causes of action, or damages arising from your use of the application or service, or in any way related to the third parties introduced to you by the application or service. you expressly waive and release any and all rights and benefits under section 1542 of the civil code of the state of california (or any analogous law of any other state), which reads as follows: "a general release does not extend to claims which the creditor does not know or suspect to exist in his favor at the time of executing the release, which, if known by him, must have materially affected his settlement with the debtor."
                                </p>
                                <p className='termsofservice-content'>
                                    the quality of the construction services scheduled through the use of the service or application is entirely the responsibility of the third party provider who ultimately provides such construction services to you. you understand, therefore, that by using the application and the service, you may be exposed to service that is potentially dangerous, offensive, harmful to minors, unsafe or otherwise objectionable, and that you use the application and the service at your own risk.
                                </p>
                                <h2 className='termsofservice-heading mt-4'>Notice</h2>
                                <p className='termsofservice-content'>
                                    The Company may give notice by means of a general notice on the Service, electronic mail to your email address on record in the Company's account information, or by written communication sent by first class mail or pre-paid post to your address on record in the Company's account information. Such notice shall be deemed to have been given upon the expiration of 48 hours after mailing or posting (if sent by first class mail or pre-paid post) or 12 hours after sending (if sent by email). You may give notice to the Company (such notice shall be deemed given when received by the Company) at any time by any of the following: letter delivered by nationally recognized overnight delivery service or first class postage prepaid mail to the Company at the following addresses (whichever is appropriate): Keepe Up, Inc., 500 Yale Avenue North, Seattle, WA 98109 addressed to the attention of: Chief Executive Officer.
                                </p>
                                <h2 className='termsofservice-heading mt-4'>Assignment</h2>
                                <p className='termsofservice-content'>
                                    This Agreement may not be assigned by you without the prior written approval of the Company but may be assigned without your consent by the Company to (i) a parent or subsidiary, (ii) an acquirer of assets, or (iii) a successor by merger. Any purported assignment in violation of this section shall be void.
                                </p>
                                <h2 className='termsofservice-heading mt-4'>Export control</h2>
                                <p className='termsofservice-content'>
                                    You agree to comply fully with all U.S. and foreign export laws and regulations to ensure that neither the Application nor any technical data related thereto nor any direct product thereof is exported or re-exported directly or indirectly in violation of, or used for any purposes prohibited by, such laws and regulations. By using the App Store Sourced Application, you represent and warrant that: (i) you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country; and (ii) you are not listed on any U.S. Government list of prohibited or restricted parties.
                                </p>
                                <h2 className='termsofservice-heading mt-4'>Dispute resolution</h2>
                                <p className='termsofservice-content'>
                                    You and Company agree that any dispute, claim or controversy arising out of or relating to this Agreement or the breach, termination, enforcement, interpretation or validity thereof or the use of the Service or Application (collectively, “Disputes”) will be settled by binding arbitration, except that each party retains the right to bring an individual action in small claims court and the right to seek injunctive or other equitable relief in a court of competent jurisdiction to prevent the actual or threatened infringement, misappropriation or violation of a party’s copyrights, trademarks, trade secrets, patents or other intellectual property rights. You acknowledge and agree that you and Company are each waiving the right to a trial by jury or to participate as a plaintiff or class User in any purported class action or representative proceeding. Further, unless both you and Company otherwise agree in writing, the arbitrator may not consolidate more than one person's claims, and may not otherwise preside over any form of any class or representative proceeding. If this specific paragraph is held unenforceable, then the entirety of this “Dispute Resolution” section will be deemed void. Except as provided in the preceding sentence, this “Dispute Resolution” section will survive any termination of this Agreement.
                                    Arbitration Rules and Governing Law. The arbitration will be administered by the American Arbitration Association (“AAA”) in accordance with the Commercial Arbitration Rules and the Supplementary Procedures for Consumer Related Disputes (the “AAA Rules”) then in effect, except as modified by this “Dispute Resolution” section. (The AAA Rules are available at <a href="https://www.adr.org/arb_med" rel="noreferrer" target="_blank">www.adr.org/arb_med</a> or by calling the AAA at 1-800-778-7879.) The Federal Arbitration Act will govern the interpretation and enforcement of this Section.
                                </p>
                                <p className='termsofservice-content'>
                                    <span className="underline">Arbitration Rules and Governing Law.</span> The arbitration will be administered by the American Arbitration Association (“AAA”) in accordance with the Commercial Arbitration Rules and the Supplementary Procedures for Consumer Related Disputes (the “AAA Rules”) then in effect, except as modified by this “Dispute Resolution” section. (The AAA Rules are available at www.adr.org/arb_med or by calling the AAA at 1-800-778-7879.) The Federal Arbitration Act will govern the interpretation and enforcement of this Section.
                                </p>
                                <p className='termsofservice-content'>
                                    <span className="underline"> Arbitration Process.</span> A party who desires to initiate arbitration must provide the other party with a written Demand for Arbitration as specified in the AAA Rules. (The AAA provides a form Demand for Arbitration at <a href="https://www.adr.org/aaa/ShowPDF?doc=ADRSTG_004175" rel="noreferrer" target="_blank">www.adr.org/aaa/ShowPDF?doc=ADRSTG_004175</a> and a separate form for California residents at <a href="https://www.adr.org/aaa/ShowPDF?doc=ADRSTG_015822" rel="noreferrer" target="_blank">www.adr.org/aaa/ShowPDF?doc=ADRSTG_015822</a>.) The arbitrator will be either a retired judge or an attorney licensed to practice law in the state of California and will be selected by the parties from the AAA’s roster of consumer dispute arbitrators. If the parties are unable to agree upon an arbitrator within seven (7) days of delivery of the Demand for Arbitration, then the AAA will appoint the arbitrator in accordance with the AAA Rules.
                                </p>
                                <p className='termsofservice-content'>
                                    <span className="underline"> Arbitration Location and Procedure.</span> Unless you and Company otherwise agree, the arbitration will be conducted in the county where you reside. If your claim does not exceed $10,000, then the arbitration will be conducted solely on the basis of documents you and Company submit to the arbitrator, unless you request a hearing or the arbitrator determines that a hearing is necessary. If your claim exceeds $10,000, your right to a hearing will be determined by the AAA Rules. Subject to the AAA Rules, the arbitrator will have the discretion to direct a reasonable exchange of information by the parties, consistent with the expedited nature of the arbitration.
                                </p>
                                <p className='termsofservice-content'>
                                    <span className="underline">Arbitrator’s Decision.</span> The arbitrator will render an award within the time frame specified in the AAA Rules. The arbitrator’s decision will include the essential findings and conclusions upon which the arbitrator based the award. Judgment on the arbitration award may be entered in any court having jurisdiction thereof. The arbitrator’s award damages must be consistent with the terms of the “Limitation of Liability” section above as to the types and the amounts of damages for which a party may be held liable. The arbitrator may award declaratory or injunctive relief only in favor of the claimant and only to the extent necessary to provide relief warranted by the claimant’s individual claim. If you prevail in arbitration you will be entitled to an award of attorneys’ fees and expenses, to the extent provided under applicable law. Company will not seek, and hereby waives all rights it may have under applicable law to recover, attorneys’ fees and expenses if it prevails in arbitration.
                                </p>
                                <p className='termsofservice-content'>
                                    <span className="underline">Fees.</span> Your responsibility to pay any AAA filing, administrative and arbitrator fees will be solely as set forth in the AAA Rules. However, if your claim for damages does not exceed $75,000, Company will pay all such fees unless the arbitrator finds that either the substance of your claim or the relief sought in your Demand for Arbitration was frivolous or was brought for an improper purpose (as measured by the standards set forth in Federal Rule of Civil Procedure 11(b)).
                                </p>
                                <p className='termsofservice-content'>
                                    <span className="underline">Changes.</span> Notwithstanding the provisions of the modification-related provisions above, if Company changes this “Dispute Resolution” section after the date you first accepted this Agreement (or accepted any subsequent changes to this Agreement), you may reject any such change by sending us written notice (including by email to support@keepe.com) within 30 days of the date such change became effective, as indicated in the “Last Updated Date” above or in the date of Company’s email to you notifying you of such change. By rejecting any change, you are agreeing that you will arbitrate any Dispute between you and Company in accordance with the provisions of this “Dispute Resolution” section as of the date you first accepted this Agreement (or accepted any subsequent changes to this Agreement).
                                </p>
                                <h2 className='termsofservice-heading mt-4'>General</h2>
                                <p className='termsofservice-content'>
                                    No joint venture, partnership, employment, or agency relationship exists between you, the Company or any third party provider as a result of this Agreement or use of the Service or Application. If any provision of the Agreement is held to be invalid or unenforceable, such provision shall be struck and the remaining provisions shall be enforced to the fullest extent under law. The failure of the Company to enforce any right or provision in this Agreement shall not constitute a waiver of such right or provision unless acknowledged and agreed to by the Company in writing. This Agreement comprises the entire agreement between you and the Company and supersedes all prior or contemporaneous negotiations, discussions or agreements, whether written or oral, between the parties regarding the subject matter contained herein.
                                </p>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="/legal/us/privacy" title="Privacy Policy">
                        <div className='mt-4 privacy-policy'>
                            <div>
                                <h2>Keepe Privacy policy</h2>
                                <p className='mt-4'>
                                    effective September 30, 2014
                                </p>
                                <p>
                                    Your privacy matters to Keepe Up, Inc. (the "Company", "we", or "us"). This Privacy Policy explains how we collect, use, share and protect information about you. We also provide information regarding how you can access and update your information and make certain choices about how your information is used.
                                </p>
                                <p>
                                    The Privacy Policy covers both our “online” (e.g., web and mobile services, including any web sites operated by us such as <a href="https://www.keepe.com" target="_blank" rel="noreferrer">www.keepe.com</a>, mobile applications, however accessed and/or used, whether via personal computers, mobile devices or otherwise) and “offline” (e.g., collection of data through mailings, telephone, or in person) activities owned, operated, provided, or made available by the Company. Our “online” and “offline” activities are collectively referenced as the “Services.” This Privacy Policy also applies to your use of interactive features or downloads that: (i) we own or control; (ii) are available through the Services; or (iii) interact with the Services and post or incorporate this Privacy Policy.
                                </p>
                                <p>
                                    BY USING OUR SERVICES OR BY OTHERWISE GIVING US YOUR INFORMATION, YOU AGREE TO THE TERMS OF THIS PRIVACY POLICY. Please review the following carefully so that you understand our privacy practices. If you do not agree to this Privacy Policy, do not use any of our Services or give us any of your information. In addition, please review our <a href="http://www.keepe.com/legal/us/terms" rel="noreferrer" target="_blank">Terms and Conditions</a>, which may apply to your use of our websites and mobile applications. This Privacy Policy is incorporated by reference into the applicable Terms and Conditions.
                                </p>
                                <p>
                                    If you have questions about this Privacy Policy, please contact us at <a href="mailto:privacy@keepe.com">privacy@keepe.com</a>.
                                    Keepe Up, Inc. complies with the U.S. – E.U. Safe Harbor framework and the U.S. - Swiss Safe Harbor framework as set forth by the U.S. Department of Commerce regarding the collection, use, and retention of personal data from European Union member countries and Switzerland. Keepe Up, Inc. has certified that it adheres to the Safe Harbor Privacy Principles of notice, choice, onward transfer, security, data integrity, access, and enforcement. To learn more about the Safe Harbor program, and to view Keepe Up, Inc.’s certification, please visit <a href="http://www.export.gov/safeharbor/" rel="noreferrer" target="_blank">http://www.export.gov/safeharbor/</a>.
                                </p>
                                <h3> Table of contents </h3>
                                <ol className="table-content">
                                    <li>
                                        <button onClick={() => document.getElementById('one').scrollIntoView()} className='link-button'>What Information Do We Collect?</button>

                                        <ol className='lower-alpha-order'>
                                            <li className='mt-2'>
                                                <button onClick={() => document.getElementById('two').scrollIntoView()} className='link-button'>Information You Provide To Us</button>
                                            </li>
                                            <li>
                                                <button onClick={() => document.getElementById('three').scrollIntoView()} className='link-button'>Information We Collect As You Access And Use Our Services</button>
                                            </li>
                                            <li>
                                                <button onClick={() => document.getElementById('four').scrollIntoView()} className='link-button'>Information Third Parties Provide About You</button>
                                            </li>
                                            <li>
                                                <button onClick={() => document.getElementById('five').scrollIntoView()} className='link-button'>Information You Provide About A Third Party</button>
                                            </li>
                                            <li>
                                                <button onClick={() => document.getElementById('six').scrollIntoView()} className='link-button'>Information Collected by Mobile Applications</button>
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <button onClick={() => document.getElementById('seven').scrollIntoView()} className='link-button'>How Do We Use The Information Collected?</button>
                                    </li>
                                    <li>
                                        <button onClick={() => document.getElementById('eight').scrollIntoView()} className='link-button'>How and When Do We Disclose Information To Third Parties?</button>
                                        <ol className='lower-alpha-order mt-2'>
                                            <li>
                                                <button onClick={() => document.getElementById('nine').scrollIntoView()} className='link-button'>When You Agree To Receive Information From Third Parties</button>
                                            </li>
                                            <li>
                                                <button onClick={() => document.getElementById('ten').scrollIntoView()} className='link-button'>Third Parties Providing Services on Our Behalf</button>
                                            </li>
                                            <li>
                                                <button onClick={() => document.getElementById('eleven').scrollIntoView()} className='link-button'>Co-branded Areas</button>
                                            </li>
                                            <li>
                                                <button onClick={() => document.getElementById('twelve').scrollIntoView()} className='link-button'>Sweepstakes, Contests And Promotions</button>
                                            </li>
                                            <li>
                                                <button onClick={() => document.getElementById('thirteen').scrollIntoView()} className='link-button'>Administrative and Legal Reasons</button>
                                            </li>
                                            <li>
                                                <button onClick={() => document.getElementById('fourteen').scrollIntoView()} className='link-button'>Business Transfer</button>
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <button onClick={() => document.getElementById('fifteen').scrollIntoView()} className='link-button'>What is Online Behavioral Advertising and How Can I Opt-out?</button>
                                    </li>
                                    <li>
                                        <button onClick={() => document.getElementById('sixteen').scrollIntoView()} className='link-button'>What About Information I Disclose Publicly?</button>
                                        <ol className='lower-alpha-order mt-2'>
                                            <li>
                                                <button onClick={() => document.getElementById('seventeen').scrollIntoView()} className='link-button'>User Generated Content and Public Information</button>
                                            </li>
                                            <li>
                                                <button onClick={() => document.getElementById('eighteen').scrollIntoView()} className='link-button'>Name and Likeness</button>
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <button onClick={() => document.getElementById('nineteen').scrollIntoView()} className='link-button'>Does Third Party Content And Links To Third Party Services Appear on Our Services</button>
                                    </li>
                                    <li>
                                        <button onClick={() => document.getElementById('twenty').scrollIntoView()} className='link-button'>What about Social Media Features and Widgets?</button>
                                    </li>
                                    <li>
                                        <button onClick={() => document.getElementById('twenty-one').scrollIntoView()} className='link-button'>How Do I Change My Information And What If I Cancel My Account?</button>
                                    </li>
                                    <li>
                                        <button onClick={() => document.getElementById('twenty-two').scrollIntoView()} className='link-button'>What Should Parents Know About Children?</button>
                                    </li>
                                    <li>
                                        <button onClick={() => document.getElementById('twenty-three').scrollIntoView()} className='link-button'>What About Security?</button>
                                    </li>
                                    <li>
                                        <button onClick={() => document.getElementById('twenty-four').scrollIntoView()} className='link-button'>What About Changes To The Privacy Policy?</button>
                                    </li>
                                    <li>
                                        <button onClick={() => document.getElementById('twenty-five').scrollIntoView()} className='link-button'>Your California Privacy Rights</button>
                                    </li>
                                    <li>
                                        <button onClick={() => document.getElementById('twenty-six').scrollIntoView()} className='link-button'>What About Consent To Transfer Information To The United States?</button>
                                    </li>
                                </ol>
                                <h2 id="one">1. What Information Do We Collect?</h2>

                                <h3 id="two">(a) Information You Provide To Us</h3>
                                <p>
                                    Personal Information. We may ask you to provide us with certain categories of information such as personal information, which is information that could reasonably be used to identify you personally, such as your name, e-mail address, and mobile number (“Personal Information”). We may collect this information through various forms and in various places through the Services, including account registration forms, contact us forms, or when you otherwise interact with us. When you sign up to use the Services, you create a user profile. The current required data fields are:
                                </p>
                                <ul className="list">
                                    <li>
                                        Email
                                    </li>
                                    <li>
                                        Password
                                    </li>
                                    <li>
                                        Name
                                    </li>
                                    <li>
                                        Mobile Phone Number
                                    </li>
                                    <li>
                                        Zip Code
                                    </li>
                                    <li>
                                        Credit Card Number, expiration date &amp; security code and or information regarding your PayPal, Google Wallet or other digital payment accounts
                                        If you choose to upload a photo when registering for our Services, the photo may be viewable by us and by the service providers so that they are able to verify your identity. You may remove or update the photo at any time by logging into your account.
                                    </li>
                                </ul>
                                <h3 id="three">(b) Information We Collect As You Access And Use Our Services </h3>
                                <p>
                                    In addition to any Personal Information or other information that you choose to submit to us, we and our third-party service providers may use a variety of technologies that automatically (or passively) collect certain information whenever you visit or interact with the Services (“Usage Information”). This Usage Information may include the browser that you are using, the URL that referred you to our Services, all of the areas within our Services that you visit, and the time of day, among other information. We may use Usage Information for a variety of purpose , including to enhance or otherwise improve the Services. In addition, we collect your IP address or other unique identifier (“Device Identifier”) for your computer, mobile or other device used to access the Services (any, a “Device”). A Device Identifier is a number that is automatically assigned to your Device used to access the Services, and our computers identify your Device by its Device Identifier. Usage Information may be non-identifying or may be associated with you. Whenever we associate Usage Information or a Device Identifier with your Personal Information, we will treat it as Personal Information.
                                </p>
                                <p>
                                    In addition, tracking information is collected as you navigate through our Services, including, but not limited to geographic areas. If you requested our third party provider Services, the service provider’s mobile phone will send your GPS coordinates, during the service, to our servers. Most GPS enabled mobile devices can define one’s location to within 50 feet. We collect this information for various purposes – including to provide you with customer support, to send you promotions and offers, to enhance our Services, and for our internal business purposes. We may also have features that allow you to share this information with other people (such as your family, friends or colleagues) if you choose.
                                </p>
                                <p>
                                    A few of the methods that may be used to collect Usage Information include, without limitation, the following (and subsequent technology and methods hereafter developed):
                                </p>
                                <p>
                                    Cookies. A cookie is a data file placed on a Device when it is used to access the Services. A Flash cookie is a data file placed on a Device via the Adobe Flash plug-in that may be built-in to or downloaded by you to your Device. Cookies and Flash Cookies may be used for many purposes, including, without limitation, remembering you and your preferences and tracking your visits to our web pages. Cookies work by assigning a number to the user that has no meaning outside of the assigning website.
                                </p>
                                <p>
                                    If you do not want information to be collected through the use of cookies, your browser allows you to deny or accept the use of cookies. Cookies can be disabled or controlled by setting a preference within your web browser or on your Device. If you choose to disable cookies or Flash cookies on your Device, some features of the Services may not function properly or may not be able to customize the delivery of information to you.
                                </p>
                                <p>
                                    You should be aware that the Company cannot control the use of cookies (or the resulting information) by third-parties, and use of third party cookies is not covered by our Privacy Policy.
                                    Web Beacons. Small graphic images or other web programming code called web beacons (also known as “1x1 GIFs” or “clear GIFs”) may be included in our web and mobile pages and messages. The web beacons are tiny graphics with a unique identifier, similar in function to cookies, and are used to track the online movements of Web users. In contrast to cookies, which are stored in a user’s computer hard drive, web beacons are embedded invisibly on Web pages and are about the size of the period at the end of this sentence. Web beacons or similar technologies help us better manage content on our Services by informing us what content is effective, count users of the Services, monitor how users navigate the Services, count how many e-mails that we send were actually opened or to count how many particular articles or links were actually viewed. We do not tie the information gathered by web beacons to our customers’ personal information.
                                </p>
                                <p>
                                    Embedded Scripts. An embedded script is programming code that is designed to collect information about your interactions with the Services, such as the links you click on. The code is temporarily downloaded onto your Device from our web server or a third party service provider, is active only while you are connected to the Services, and is deactivated or deleted thereafter.
                                </p>
                                <h3 id="four">(c) Information Third Parties Provide About You </h3>

                                <p>
                                    We may, from time to time, supplement the information we collect about you through our web site or Mobile Application with outside records from third parties in order to enhance our ability to serve you, to tailor our content to you and to offer you opportunities to purchase products or services that we believe may be of interest to you. We may combine the information we receive from those sources with information we collect through the Services. In those cases, we will apply this Privacy Policy to any Personal Information received, unless we have disclosed otherwise.
                                </p>
                                <h3 id="five">(d) Information You Provide About A Third Party</h3>
                                <p>
                                    If you choose to use our referral service to tell a friend about our Services or a job position, we will ask you for your friend’s name and email address. We will automatically send your friend a one-time email inviting him or her to visit the Services. We store this information for the sole purpose of sending this one-time email and tracking the success of our referral program, and do not use this information for any other marketing purpose unless we obtain consent from that person or we explicitly say otherwise. Please be aware that when you use any send-to-a-friend functionality through our Services, your e-mail address may be included in the communication sent to your friend.
                                </p>
                                <p>
                                    Your friend may contact us through <a href="https://www.keepe.com/support">www.keepe.com/support</a> to request that we remove this information from our database.
                                </p>
                                <h3 id="six">(e) Information Collected by Mobile Applications</h3>
                                <p>
                                    Our Services are primarily provided through an application on your mobile, tablet computer or similar device (“Mobile Application”). You agree that we may collect and use technical data and related information, including but not limited to, technical information about your device, system and application software, and peripherals, that is gathered periodically to facilitate the provision of software updates, product support and other services to you (if any) related to such Mobile Applications.
                                </p>
                                <p>
                                    When you use any of our Mobile Applications, the Mobile Application may automatically collect and store some or all of the following information from your mobile device (“Mobile Device Information”), including without limitation:
                                </p>
                                <ol className='info-collected'>
                                    <li>
                                        Your preferred language and country site (if applicable)
                                    </li>
                                    <li>
                                        Your phone number or other unique device identifier assigned to your mobile device – such as the International Mobile Equipment Identity or the Mobile Equipment ID number
                                    </li>
                                    <li>
                                        The IP address of your mobile device
                                    </li>
                                    <li>
                                        The manufacturer and model of your mobile device
                                    </li>
                                    <li>
                                        Your mobile operating system
                                    </li>
                                    <li>
                                        The type of mobile Internet browsers you are using
                                    </li>
                                    <li>
                                        Your geolocation
                                    </li>
                                    <li>
                                        Information about how you interact with the Mobile Application and any of our web sites to which the Mobile Application links, such as how many times you use a specific part of the mobile application over a given time period, the amount of time you spend using the Mobile Application, how often you use the Mobile Application, actions you take in the Mobile Application and how you engage with the Mobile Application
                                    </li>
                                    <li>
                                        Information to allow us to personalize the services and content available through the Mobile Application
                                    </li>
                                </ol>
                                <p>
                                    We may use information automatically collected by the Mobile Application (including the Mobile Device Information) in the following ways:
                                </p>
                                <ol className='info-collected'>
                                    <li>
                                        To operate and improve our Mobile Applications, other Services, our company’s services, and tools;
                                    </li>
                                    <li>
                                        To create aggregated and anonymized information to determine which Mobile Application features are most popular and useful to users, and for other statistical analyses;
                                    </li>
                                    <li>
                                        To prevent, discover and investigate violations of this Privacy Policy or any applicable terms of service or terms of use for the Mobile Application, and to investigate fraud, chargeback or other matters;
                                    </li>
                                    <li>
                                        To customize the content or services on the Mobile Application for you, or the communications sent to you through the Mobile Application.
                                    </li>
                                </ol>
                                <p>
                                    With respect to geo-location data we track through your Mobile Device, we use that geo-location information for various purposes – including for you to be able to view the service providers in your area that are close to your location, for you to set your service location, so the service providers are able to find the service location, to send you promotions and offers, and to allow you (if you choose through any features we may provide) to share this information with other people. Except as otherwise permitted in this Privacy Policy, we will not share this information with third parties for any purpose and will only use this information for the sole purpose of providing you with the ability to request construction services via Keepe’s Mobile Application. You may at any time no longer allow our Mobile Application to use your location by turning this feature off at the Mobile Device level.
                                </p>
                                <p>
                                    We also provide some of your Personal Information (such as your first name, service address and your photo, if you have chosen to upload your photo to your profile) to the service provider/partner who accepts your request for construction services so that the service provider may contact and find you. The companies for which service providers work (that are providing the construction service) are also able to access your Personal Information, including your geo-location data.
                                </p>
                                <p>
                                    We may associate your unique mobile Device Identifier or Mobile Application usage information with any Personal Information you provide, but we will treat the combined information as Personal Information.
                                </p>
                                <p>
                                    Personal Information may also be collected and shared with third-parties if there is content from the Mobile Application that you specifically and knowingly upload to, share with or transmit to an email recipient, online community, website, or to the public, e.g. uploaded photos, posted reviews or comments, or information about you or your ride that you choose to share with others through features which may be provided on our Services. This uploaded, shared or transmitted content will also be subject to the privacy policy of the email, online community website, social media or other platform to which you upload, share or transmit the content.
                                </p>
                                <h3>(f) Information Collected from Job Applicants</h3>
                                <p>
                                    If you wish to apply for a job on our web site(s), we will collect Personal Information such as your name, email address, phone number and may collect additional information such as resume, gender, and your ethnicity. We use the information collected within this area of the web site(s) to determine your qualifications for the position in which you have applied and to contact you to set up an interview.
                                </p>
                                <h3 id="seven">2. How Do We Use The Information Collected?</h3>
                                <p>
                                    Our primary goal in collecting your Personal information or Usage Information is to provide you with an enhanced experience when using the Services.
                                </p>
                                <p>
                                    Based upon the Personal Information you provide us when registering for an account, we will send you a welcoming email to verify your username and password. We will also communicate with you in response to your inquiries, to provide the services you request, and to manage your account. We will communicate with you by email, telephone, or SMS or text message, in accordance with your wishes.
                                </p>
                                <p>
                                    We use your information to closely monitor which features of the Services are used most, to allow you to view your trip history, store your credit card information on a secure page, view any promotions we may currently be running, rate trips, and to determine which features we need to focus on improving, including usage patterns and geographic locations to determine where we should offer or focus services, features and/or resources.
                                </p>
                                <p>
                                    We use the information collected from our Mobile Application so that we are able to serve you the correct app version depending on your device type, for troubleshooting and in some cases, marketing purposes. The credit card information you provide in your personal profile at sign-up is not stored by us, but is stored and used by our third party credit card processors in order for them to process payment that you owe third parties for construction services received by you.
                                </p>
                                <p>
                                    We use your Internet Protocol (IP) address to help diagnose problems with our computer server, and to administer our web site(s). Your IP address is used to help identify you, but contains no personal information about you.
                                </p>
                                <p>
                                    We will send you strictly service-related announcements on rare occasions when it is necessary to do so. For instance, if our Services are temporarily suspended for maintenance, we might send you an email. Generally, you may not opt-out of these communications, which are not promotional in nature. If you do not wish to receive them, you have the option to deactivate your account.
                                </p>
                                <p>
                                    In addition, we may use your Personal Information or Usage Information that we collect about you: (1) to provide you with information or services or process transactions that you have requested or agreed to receive including to send you electronic newsletters, or to provide you with special offers or promotional materials on behalf of us or third parties; (2) to process your registration with the Services, including verifying your information is active and valid; (3) to improve the Services or our services, to customize your experience with the Services, or to serve you specific content that is most relevant to you; (4) to enable you to participate in a variety of the Services’ features such as online or mobile entry sweepstakes, contests or other promotions; (5) to contact you with regard to your use of the Services and, in our discretion, changes to the Services and/or the Services’ policies; (6) for internal business purposes; (7) for inclusion in our data analytics; and (8) for purposes disclosed at the time you provide your information or as otherwise set forth in this Privacy Policy.
                                </p>
                                <p>
                                    Please note that information submitted to the Services via a “contact us” or other similar function may not receive a response.
                                </p>
                                <h3 id="eight">3. How and When Do We Disclose Information to Third Parties?</h3>
                                <p>
                                    We may share non-personally identifiable information, such as aggregated user statistics and log data, with third parties for industry analysis, demographic profiling, to deliver targeted advertising about other products or services, or for other business purposes. We do not sell, share, rent or trade the information we have collected about you, including Personal Information, other than as disclosed within this Privacy Policy or at the time you provide your information. We do not share your Personal Information with third parties for those third parties’ direct marketing purposes unless you consent to such sharing at the time you provide your Personal Information.
                                </p>
                                <h3 id="nine">(a) When You Agree To Receive Information From Third Parties.</h3>
                                <p>
                                    You may be presented with an opportunity to receive information and/or marketing offers directly from third parties. If you do agree to have your Personal Information shared, your Personal Information will be disclosed to such third parties and all information you disclose will be subject to the privacy policy and practices of such third parties. We are not responsible for the privacy policies and practices of such third parties and, therefore, you should review the privacy policies and practices of such third parties prior to agreeing to receive such information from them. If you later decide that you no longer want to receive communication from a third party, you will need to contact that third party directly.
                                </p>
                                <h3 id="ten">(b) Third Parties Providing Services on Our Behalf.</h3>
                                <p>
                                    We use third party companies and individuals to facilitate our Services, provide or perform certain aspects of the Services on our behalf – such as service providers and companies they work for to provide the Services, and other third-parties to host the Services, design and/or operate the Services’ features, track the Services’ analytics, process payments, engage in anti-fraud and security measures, provide customer support, provide geo-location information to our service providers, enable us to send you special offers, host our job application form, perform technical services (e.g., without limitation, maintenance services, database management, web analytics and improvement of the Services‘ features), or perform other administrative services. We may provide these vendors with access to user information, including Personal Information, this information sharing is limited to only the information needed by the vendor to carry out the services they are performing for you or for us. Each of these vendors are obligated not to disclose or use Personal Information for any other purpose.
                                </p>
                                <p>
                                    While we may use third party analytics service providers to evaluate and provide us with information about the use of the Services and viewing of our content, we do not share Personal Information with these analytics service providers, but they may set and access their own cookies, web beacons and embedded scripts on your Device and they may otherwise collect or have access to information about you, including non-personally identifiable information.
                                    We use a third party hosting provider who hosts our support section of our website. Information collected within this section of our web site is governed by our Privacy Policy.
                                </p>
                                <h3 id="eleven">(c) Co-branded Services.</h3>
                                <p>
                                    Certain aspects of the Services may be provided to you in association with third parties (“Co-Branded Services”) such as sponsors and charities, and may require you to disclose Personal Information to them. Such Co-Branded Services will identify the third party. If you elect to register for products and/or services through the Co-Branded Services, you may be providing your information to both us and the third party. Further, if you sign-in to a Co-Branded Service with a username and password obtained through our Services, your Personal Information may be disclosed to the identified third parties for that Co-Branded Service and will be subject to their posted privacy policies.
                                </p>
                                <h3 id="twelve">(d) Sweepstakes, Contests and Promotions.</h3>
                                <p>
                                    We may offer sweepstakes, contests, and other promotions (any, a “Promotion”) through the Services that may require registration. By participating in a Promotion, you are agreeing to official rules that govern that Promotion, which may contain specific requirements of you, including, allowing the sponsor of the Promotion to use your name, voice and/or likeness in advertising or marketing associated with the Promotion. If you choose to enter a Promotion, Personal Information may be disclosed to third parties or the public in connection with the administration of such Promotion, including, in connection with winner selection, prize fulfillment, and as required by law or permitted by the Promotion’s official rules, such as on a winners list.
                                </p>
                                <h3 id="thirteen">(e) Administrative and Legal Reasons.</h3>
                                <p>
                                    We cooperate with government and law enforcement officials and private parties to enforce and comply with the law. Thus, we may access, use, preserve, transfer and disclose your information (including Personal Information), including disclosure to third parties such as government or law enforcement officials or private parties as we reasonably determine is necessary and appropriate: (i) to satisfy any applicable law, regulation, subpoenas, governmental requests or legal process; (ii) to protect and/or defend the Terms and Conditions for online and mobile Services or other policies applicable to any online and mobile Services, including investigation of potential violations thereof; (iii) to protect the safety, rights, property or security of the Company, our Services or any third party; (iv) to protect the safety of the public for any reason; (v) to detect, prevent or otherwise address fraud, security or technical issues; an /or (vi) to prevent or stop activity we may consider to be, or to pose a risk of being, an illegal, unethical, or legally actionable activity. Further, we may use IP address or other Device Identifiers, to identify users, and may do so in cooperation with third parties such as copyright owners, internet service providers, wireless service providers and/or law enforcement agencies, including disclosing such information to third parties, all in our discretion. Such disclosures may be carried out without notice to you.
                                </p>
                                <h3 id="fourteen"> (f) Business Transfer.</h3>
                                <p>
                                    We may share your information, including your Personal Information and Usage Information with our parent, subsidiaries and affiliates for internal reasons. We also reserve the right to disclose and transfer all such information: (i) to a subsequent owner, co-owner or operator of the Services or applicable database; or (ii) in connection with a corporate merger, consolidation, restructuring, the sale of substantially all of our membership interests and/or assets or other corporate change, including, during the course of any due diligence process.
                                </p>
                                <h2 id="fifteen">4. What is Online Behavioral Advertising and How Can I Opt-Out?</h2>
                                <p>
                                    Targeted advertising (also known as Behavioral Advertising) uses information collected on an individual's web or mobile browsing behavior such as the pages they have visited or the searches they have made. This information is then used to select which advertisements should be displayed to a particular individual on websites other than our web site(s). For example, if you have shown a preference for paint colors while visiting our web site(s), you may be served an advertisement for paint-related programs when you visit a site other than our web site(s). The information collected is only linked to an anonymous cookie ID (alphanumeric number); it does not include any information that could be linked back to a particular person, such as their name, address or credit card number. The information used for targeted advertising either comes from us or through third party website publishers.
                                </p>
                                <p>
                                    If you would like to opt out of targeted advertising from us that occurs when visiting our third party advertising publishers, please click here to access the NAI Opt-Out Page. Please note that this will opt you out of targeted ads from our Company and any other participating advertisers. If you opt out, you may continue to receive online advertising from us; however, these ads may not be as relevant to you.
                                </p>
                                <p>
                                    In order for behavioral advertising opt-outs to work on your Device, your browser must be set to accept cookies. If you delete cookies, buy a new Device, access our Services from a different device, login under a different screen name, or change web browsers, you will need to opt-out again. If your browser has scripting disabled, you do not need to opt out, as online behavioral advertising technology does not work when scripting is disabled. Please check your browser's security settings to validate whether scripting is active or disabled.
                                </p>
                                <p>
                                    Additionally, many network advertising programs allow you to view and manage the interest categories they have compiled from your online browsing activities. These interest categories help determine the types of targeted advertisements you may receive. The NAI Opt-Out Page provides a tool that identifies its member companies that have cookies on your browser and provides links to those companies.
                                </p>
                                <h2 id="sixteen">5. What About Information I Disclose Publicly?</h2>
                                <h2 id="seventeen">(a) User Generated Content and Public Information.</h2>
                                <p>
                                    The Services may offer publicly accessible blogs or community forums or other ways to permit you to submit ideas, photographs, user profiles, writings, music, video, audio recordings, computer graphics, pictures, data, questions, comments, suggestions or other content, including Personal Information (collectively, “User Content”). We or others may reproduce, publish, distribute or otherwise use User Content online or offline in any media or format (currently existing or hereafter developed). Others may have access to this User Content and may have the ability to share it with third parties across the Internet. You should be aware that any User Content you provide in these areas may be read, collected, and use by others who access them. Thus, please think carefully before deciding what information you share, including Personal Information, in connection with your User Content. Please note that Company does not control who will have access to the information that you choose to make public, and cannot ensure that parties who have access to such publicly available information will respect your privacy or keep it secure. This Privacy Policy does not apply to any information that you disclose publicly, share with others or otherwise upload, whether through the Services or otherwise. We are not responsible for the accuracy, use or misuse of any content or information that you disclose or receive through the Services.
                                </p>
                                <p>
                                    To request removal of your User Content from our blog or community forum or similar features, contact us through <a href="https://www.keepe.com/support" rel="noreferrer" target="_blank">www.keepe.com/support</a>. In some cases, we may not be able to remove your User content, in which case we will let you know if we are unable to do so and why.
                                </p>
                                <h2 id="eighteen">(b) Name and Likeness.</h2>
                                <p>
                                    We may also publish your name, voice, likeness and other Personal Information that is part of your User Content, and we may use the content, or any portion of the content, for advertising, marketing, publicity and promotional activities. For full terms and conditions regarding User Content you submit to the Services, please review our <a href="https://www.keepe.com/legal/us/privacy">Terms and Conditions</a>.
                                </p>
                                <h2 id="nineteen">6. Does Third Party Content And Links To Third Party Services Appear on the Services?</h2>
                                <p>
                                    The Services may contain content that is supplied by a third party, and those third parties may collect web site usage information and your Device Identifier when web pages from any online or mobile Services are served to your browser. In addition, when you are using the Services, you may be directed to other sites or applications that are operated and controlled by third parties that we do not control. We are not responsible for the privacy practices employed by any of these third parties. For example, if you click on a banner advertisement, the click may take you away from one of our websites onto a different web site. These other web sites may send their own cookies to you, independently collect data or solicit Personal Information and may or may not have their own published privacy policies. We encourage you to note when you leave our Services and to read the privacy statements of all third party web sites or applications before submitting any Personal Information to third parties.
                                </p>
                                <h2 id="twenty">7. What About Social Media Features and Widgets?</h2>
                                <p>
                                    Our online and mobile Services may include social media features, such as the Facebook Like button, and widgets such as a “Share This” button, or interactive mini-programs that run on our online and mobile Services. These features may collect your IP address, which page you are visiting on our online or mobile Services, and may set a cookie to enable the feature to function properly. Social media features and widgets are either hosted by a third party or hosted directly on our online Services. Your interactions with these features and widgets are governed by the privacy policy of the company providing them.
                                </p>
                                <h2 id="twenty-one">8. How Do I Change My Information and What If I Cancel My Account?</h2>
                                <p>
                                    You are responsible for maintaining the accuracy of the information you submit to us, such as your contact information provided as part of account registration. If your Personal Information changes, or if you no longer desire our Services, you may correct, delete inaccuracies, or amend information by making the change on our member information page or by contacting us through <a href="https://www.keepe.com/support" rel="noreferrer" target="_blank">www.keepe.com/support</a>. We will make good faith efforts to make requested changes in our then active databases as soon as reasonably practicable.
                                </p>
                                <p>
                                    You may also cancel or modify your communications that you have elected to receive from the Services by following the instructions contained within an e-mail or by logging into your user account and changing your communication preferences.
                                </p>
                                <p>
                                    If you wish to cancel your account or request that we no longer use your information to provide you services, contact us through <a href="https://www.keepe.com/support" rel="noreferrer">www.keepe.com/support</a>.
                                </p>
                                <p>
                                    We will retain your Personal Information and Usage Information (including geo-location) for as long as your account with the Services is active and as needed to provide you services. Even after your account is terminated, we will retain your Personal Information and Usage Information (including geo-location, trip history, credit card information and transaction history) as needed to comply with our legal and regulatory obligations, resolve disputes, conclude any activities related to cancellation of an account (such as addressing chargebacks from your credit card companies), investigate or prevent fraud and other inappropriate activity, to enforce our agreements, and for other business reason. After a period of time, your data may be anonymized and aggregated, and then may be held by us as long as necessary for us to provide our Services effectively, but our use of the anonymized data will be solely for analytic purposes.
                                </p>
                                <h2 id="twenty-two"> 9. What Should Parents Know About Children? </h2>
                                <p>
                                    The Company cares about the safety of children. Because our Services are not directed toward minors, no one under 18 (and certainly no children under 13) are allowed to register with or use the Services. We do not knowingly collect personal information from anyone under the age of 18. If we discover that we have collected personal information from a person under 18, we will delete that information immediately. If you are a parent or guardian of a minor under the age of eighteen (18) and believe he or she has disclosed Personal Information to us, please contact us at <a href="mailto:privacy@keepe.com">privacy@keepe.com.</a>.
                                </p>
                                <h2 id="twenty-three">10. What About Security?</h2>
                                <p>
                                    The Personal Information and Usage Information we collect is securely stored within our databases, and we use standard, industry-wide, commercially reasonable security practices such as encryption, firewalls and SSL (Secure Socket Layers) for protecting your information - such as any portions of your credit card number which we retain (we do not ourselves retain your entire credit card information) and geo-location information. However, as effective as encryption technology is, no security system is impenetrable. We cannot guarantee the security of our databases, nor can we guarantee that information you supply won't be intercepted while being transmitted to us over the Internet or wireless communication, and any information you transmit to the Company you do at your own risk. We recommend that you not disclose your password to anyone.
                                </p>
                                <h2 id="twenty-four">11. What About Changes To The Privacy Policy?</h2>
                                <p>
                                    From time to time, we may update this Privacy Policy to reflect changes to our information practices. Any changes will be effective immediately upon the posting of the revised Privacy Policy. If we make any material changes, we will notify you by email (sent to the e-mail address specified in your account) or by means of a notice on the Services prior to the change becoming effective. We encourage you to periodically review this page for the latest information on our privacy practices.
                                </p>
                                <h2 id="twenty-five">12. Your California Privacy Rights</h2>
                                <p>
                                    California’s “Shine the Light” law, California Civil Code § 1798.83, requires certain businesses to respond to requests from California customers (those who have an established business relationship with us) asking about the business’ practices related to disclosing personal information to third parties for the third parties’ direct marketing purposes. Alternately, such businesses may have in place a policy not to disclose personal information of customers to third parties for the third parties’ direct marketing purposes unless the customer first affirmatively agrees to the disclosure (opt-in) or if the customer has exercised an option to opt-out of such information-sharing (opt-out).
                                </p>
                                <p>
                                    We have opted for this alternative approach, and we do not share personal information of customers information to third parties for the third parties’ direct marketing purposes unless you provide us with permission at the time you provide such customer information.
                                </p>
                                <h2 id="twenty-six">13. What About Consent To Transfer Information To The United States?</h2>
                                <p className='mb-5'>
                                    If you are located anywhere outside of the United States, please be aware that information we collect, including, Personal Information, will be transferred to, processed and stored in the United States. The data protection laws in the United States may differ from those of the country in which you are located, and your Personal Information may be subject to access requests from governments, courts, or law enforcement in the United States according to laws of the United States. By using the Services or providing us with any information, you consent to this transfer, processing and storage of your information in the United States.
                                </p>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="/legal/us/copyright" title="Copyright">
                        <div className='mt-4 copyright'>
                            <div>
                                <h2>Keepe Copyright policy notification of copyright infringement:</h2>
                                <p className='mt-4'>
                                    Keepe Up, Inc. (“Keepe”) respects the intellectual property rights of others and expects its users to do the same.
                                </p>
                                <p className='mt-4'>
                                    It is Keepe’s policy, in appropriate circumstances and at its discretion, to disable and/or terminate the accounts of users who repeatedly infringe or are repeatedly charged with infringing the copyrights or other intellectual property rights of others.
                                </p>
                                <p>
                                    In accordance with the Digital Millennium Copyright Act of 1998, the text of which may be found on the U.S. <a href="http://www.copyright.gov/legislation/dmca.pdf" rel="noreferrer" target="_blank">Copyright Office website</a>., Keepe will respond expeditiously to claims of copyright infringement committed using the Keepe website or other online network accessible through a mobile device or other type of device (the “Sites”) that are reported to Keepe’s Designated Copyright Agent, identified in the sample notice below.
                                </p>
                                <p>
                                    If you are a copyright owner, or are authorized to act on behalf of one, or authorized to act under any exclusive right under copyright, please report alleged copyright infringements taking place on or through the Sites by completing the following DMCA Notice of Alleged Infringement and delivering it to Keepe’s Designated Copyright Agent. Upon receipt of the Notice as described below, Keepe will take whatever action, in its sole discretion, it deems appropriate, including removal of the challenged material from the Sites.
                                </p>
                                <h3>DMCA Notice of alleged infringement ("Notice")</h3>
                                <ol className='copyright-points'>
                                    <li>
                                        Identify the copyrighted work that you claim has been infringed, or - if multiple copyrighted works are covered by this Notice - you may provide a representative list of the copyrighted works that you claim have been infringed.
                                    </li>
                                    <li>
                                        Identify the material that you claim is infringing (or to be the subject of infringing activity) and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the 2. material, including at a minimum, if applicable, the URL of the link shown on the Site(s) where such material may be found.
                                    </li>
                                    <li>
                                        Provide your mailing address, telephone number, and, if available, email address.
                                    </li>
                                    <li>
                                        Include both of the following statements in the body of the Notice:
                                    </li>
                                    <li>
                                        "I hereby state that I have a good faith belief that the disputed use of the copyrighted material is not authorized by the copyright owner, its agent, or the law (e.g., as a fair use)."
                                    </li>
                                    <li>
                                        "I hereby state that the information in this Notice is accurate and, under penalty of perjury, that I am the owner, or authorized to act on behalf of the owner, of the copyright or of an exclusive right under the copyright that is allegedly infringed."
                                    </li>
                                    <li>
                                        Provide your full legal name and your electronic or physical signature.
                                    </li>
                                    <li>
                                        Deliver this Notice, with all items completed, to Keepe’s Designated Copyright Agent:
                                    </li>
                                </ol>
                                <address className='mb-5'>
                                    <span> Copyright Agent </span>
                                    <span> c/o Keepe Up, Inc. </span>
                                    <span> 901 5th Ave Ste 1950 </span>
                                    <span> Seattle, WA 98164 </span>
                                    <a href="tel:18669476215"> Tel: +1 866-947-6215 </a>
                                    <a href="mailto:copyright@keepe.com">Email: copyright@keepe.com</a>
                                </address>
                            </div>
                        </div>
                    </Tab>
                </Tabs>
            </div>
            <Footer />
        </>

    )
}
